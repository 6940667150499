export const TABLE_LABEL = {
  approval: "Aprobada",
  card_out: "Card Out",
  cash_out: "Cash Out",
  commerce: "Comercio",
  credit: "Recarga",
  credit_symbol: "+",
  date: "F. Creación",
  debit: "Débito",
  debit_symbol: "-",
  declined: "Rechazada",
  empty_result: "No se encontró ninguna coincidencia",
  empty_result_description:
    "Modifica la búsqueda para encontrar nuevos resultados",
  footer_pagination: "Transacciones por página",
  kushki: "Kushki",
  liquidacion: "Liquidación",
  transfer_out: "Transfer Out",
};
