import { IModalDetailCardProps } from "@kushki/connect-ui/dist/Components/Organism/ModalDetailCard";
import {
  buildDateDetailModal,
  formatCurrencyWallet,
  renderSymbol,
  setOriginText,
  setTransactionTypeText,
} from "../../../shared/utils/walletUtils";
import { defaultTo, get } from "lodash";
import { WalletTransactionPath } from "../../../shared/enums/wallet_transaction_path";
import { IModalHeaderProps } from "@kushki/connect-ui/dist/Components/Molecules";
import { FilterEnum } from "../../../shared/enums/filter";
import { TEXT_TRANSFORM_COLUMNS } from "../../../shared/constants/table_container";
import {
  IModalDetailCard,
  IUseDetailModalComponent,
  ModalData,
} from "../DetailModal.interfaces";
import { setShowDetailModal } from "../../../store/actions/walletTransaction.action";
import { Dispatch } from "redux";
import { useAppDispatch } from "../../../store/hooks/storeHook";
import { ColorEnum } from "../../../shared/enums/cell_color";
import { OperationTypeEnum } from "../../../shared/constants/head_filters";

export const MODAL_DETAIL_ARGS_DEFAULT: Omit<
  IModalDetailCardProps,
  "onClose" | "header"
> = {
  bottom: "0",
  isLoading: false,
  isOpen: false,
  right: "0",
  slideDirection: "left",
  top: "15%",
  variant: "full",
  widthCard: "576px",
};

export const enum DetailTitles {
  OPERATION_TYPE = "Tipo de Operación",
  ORIGIN = "Origen",
  ORIGIN_TICKET_NUMBER = "Ticket Number Origin",
  ECONOMIC_ACTIVITY = "Actividad Económica",
  OPERATION_AMOUNT = "Monto de operación",
  COMMISSION = "Comisión",
  FIXED_RATE = "Tarifa fija",
  PERCENTAGE = "% Variable",
  COMMISSION_IVA = "IVA de Comisión",
}

export const bodyColumns: Record<string, number> = {
  [OperationTypeEnum.KUSHKI_RECHARGE]: 6,
  [OperationTypeEnum.TRANSFER_OUT_DEBIT]: 4,
  [OperationTypeEnum.TRANSFER_OUT_RECHARGE]: 4,
  [OperationTypeEnum.KUSHKI_DEBIT]: 6,
  [OperationTypeEnum.CARD_OUT_DEBIT]: 5,
  [OperationTypeEnum.CARD_OUT_RECHARGE]: 5,
  [OperationTypeEnum.CASH_OUT_DEBIT]: 4,
  [OperationTypeEnum.CASH_OUT_RECHARGE]: 4,
  [OperationTypeEnum.COMMERCE_RECHARGE]: 4,
  [OperationTypeEnum.LIQUIDATION_RECHARGE]: 4,
};

export const useDetailModal = (
  props: IModalDetailCard
): IUseDetailModalComponent => {
  const { trx, country } = props;
  const dispatch: Dispatch<any> = useAppDispatch();

  const operationType: string = ((): string => {
    return `${setTransactionTypeText(
      get(trx, WalletTransactionPath.TRX_TYPE, "")
    )} ${setOriginText(trx)}`;
  })();

  const header: Omit<IModalHeaderProps, "isLoading"> = ((): Omit<
    IModalHeaderProps,
    "isLoading"
  > => {
    return {
      secondaryText: `Fecha y hora de la creación: ${buildDateDetailModal(
        trx.created,
        true
      )} - ${buildDateDetailModal(trx.created, false)}`,
      tags: [
        {
          color:
            trx.transaction_status === FilterEnum.APPROVAL
              ? ColorEnum.SUCCESS
              : ColorEnum.WARNING,
          text: defaultTo(TEXT_TRANSFORM_COLUMNS[trx.transaction_status], ""),
        },
      ],
      title: "Detalle del movimiento",
      titleDescription: trx.transaction_id,
    };
  })();

  const getInfoLabels = (): ModalData[] => {
    const operation_amount_description: string = `${renderSymbol(
      get(trx, WalletTransactionPath.TRX_TYPE, "")
    )} ${formatCurrencyWallet(
      Number(get(trx, WalletTransactionPath.TOTAL_AMOUNT)),
      country
    )} ${get(trx, WalletTransactionPath.CURRENCY)}`;

    switch (operationType) {
      case OperationTypeEnum.CARD_OUT_RECHARGE:
      case OperationTypeEnum.CASH_OUT_DEBIT:
      case OperationTypeEnum.CASH_OUT_RECHARGE:
      case OperationTypeEnum.COMMERCE_RECHARGE:
      case OperationTypeEnum.LIQUIDATION_RECHARGE:
        return [
          {
            description: operationType,
            title: DetailTitles.OPERATION_TYPE,
          },
          {
            description: get(trx, WalletTransactionPath.MERCHANT_NAME, ""),
            title: DetailTitles.ORIGIN,
          },
          {
            description: get(
              trx,
              WalletTransactionPath.ORIGIN_TICKET_NUMBER,
              ""
            ),
            title: DetailTitles.ORIGIN_TICKET_NUMBER,
          },
          {
            description: get(trx, WalletTransactionPath.DESCRIPTION, ""),
            title: DetailTitles.ECONOMIC_ACTIVITY,
          },
          {
            description: operation_amount_description,
            title: DetailTitles.OPERATION_AMOUNT,
          },
        ];

      case OperationTypeEnum.KUSHKI_RECHARGE:
      case OperationTypeEnum.KUSHKI_DEBIT:
        return [
          {
            description: operationType,
            title: DetailTitles.OPERATION_TYPE,
          },
          {
            description: get(trx, WalletTransactionPath.MERCHANT_NAME, ""),
            title: DetailTitles.ORIGIN,
          },
          {
            description: get(trx, WalletTransactionPath.DESCRIPTION, ""),
            title: DetailTitles.ECONOMIC_ACTIVITY,
          },
          {
            description: operation_amount_description,
            title: DetailTitles.OPERATION_AMOUNT,
          },
        ];
      case OperationTypeEnum.CARD_OUT_DEBIT:
      case OperationTypeEnum.TRANSFER_OUT_DEBIT:
      case OperationTypeEnum.TRANSFER_OUT_RECHARGE:
        return [
          {
            description: operationType,
            title: DetailTitles.OPERATION_TYPE,
          },
          {
            description: get(trx, WalletTransactionPath.MERCHANT_NAME, ""),
            title: DetailTitles.ORIGIN,
          },
          {
            description: get(
              trx,
              WalletTransactionPath.ORIGIN_TICKET_NUMBER,
              ""
            ),
            title: DetailTitles.ORIGIN_TICKET_NUMBER,
          },
          {
            description: get(trx, WalletTransactionPath.DESCRIPTION, ""),
            title: DetailTitles.ECONOMIC_ACTIVITY,
          },
          {
            description: operation_amount_description,
            title: DetailTitles.OPERATION_AMOUNT,
          },
          {
            description: "",
            title: "",
          },
          {
            description: get(trx, WalletTransactionPath.KUSHKI_FEE, ""),
            title: DetailTitles.COMMISSION,
          },
          {
            description: get(trx, "fixed_fee", 0),
            title: DetailTitles.FIXED_RATE,
          },
          {
            description: get(trx, "variable_fee", 0),
            title: DetailTitles.PERCENTAGE,
          },
          {
            description: get(trx, "iva_amount", 0),
            title: DetailTitles.COMMISSION_IVA,
          },
        ];

      default:
        return [{ description: "", title: "" }];
    }
  };

  const onClose = (): void => {
    dispatch(setShowDetailModal(false));
  };

  return {
    header,
    items: getInfoLabels(),
    onClose,
    operationType,
  };
};
