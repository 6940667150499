export enum FilterEnum {
  APPROVAL = "APPROVAL",
  DECLINED = "DECLINED",
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
  CARD_OUT = "usrv-payouts-card",
  CASH_OUT = "usrv-payouts-cash",
  LIQUIDACION = "usrv-dispersions",
  TRANSFER_OUT = "usrv-payouts-transfer",
  MANUAL = "manual",
  AUTOMATIC = "automatic",
  ASC = "asc",
  DESC = "desc",
}

export enum OperationTypeLabelEnum {
  KUSHKI_RECHARGE = "Recarga - Kushki",
  LIQUIDATION_RECHARGE = "Recarga - Liquidación",
  COMMERCE_RECHARGE = "Recarga - Comercio",
  CARD_OUT_RECHARGE = "Recarga - Card Out",
  CASH_OUT_RECHARGE = "Recarga - Cash Out",
  TRANSFER_OUT_RECHARGE = "Recarga - Transfer Out",
  CASH_OUT_DEBIT = "Débito - Cash Out",
  CARD_OUT_DEBIT = "Débito - Card Out",
  TRANSFER_OUT_DEBIT = "Débito - Transfer Out",
  KUSHKI_DEBIT = "Débito - Kushki",
}

export enum StatusFilterEnum {
  APPROVAL = "Aprobado",
  DECLINED = "Rechazado",
}

export enum FilterLabelEnum {
  OPERATION_TYPE = "Tipo de operación",
  STATUS = "Estado",
  AMOUNT = "Montos",
}

export enum AmountFilterFieldEnum {
  EQUAL = "eq",
  MIN = "min",
  MAX = "max",
  RANGE = "range",
}

export enum DateFormatStringEnum {
  PATTERN_yyyyMMdd = "yyyy-MM-dd",
  PATTERN_ddMMyyyy = "dd/MM/yyyy",
  PATTERN_T000000 = "T00:00:00",
  PATTERN_T235959 = "T23:59:59",
  PATTERN_HHmmss = "HH:mm:ss",
  PATTERN_HHmm = "HH:mm",
}
