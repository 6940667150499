import { defaultTo, get } from "lodash";
import { CountriesEnum } from "../enums/countries";
import { TableBodyCellEnum } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/constants";
import { ICellRow } from "@kushki/connect-ui";
import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/interfaces";
import { DateFormatStringEnum, FilterEnum } from "../enums/filter";
import { TABLE_LABEL } from "../constants/labels/table_labels";
import format from "date-fns/format";
import { ColorEnum } from "../enums/cell_color";
import {
  TEXT_TRANSFORM_COLUMNS,
  TEXT_TWO_LINES,
} from "../constants/table_container";
import { WalletTransactionPath } from "../enums/wallet_transaction_path";
import { WalletTransaction } from "../../../types/wallet_transaction";

export const formatCurrencyWallet = (
  amount: number,
  country: string
): string => {
  const formattedAmount: string =
    country === CountriesEnum.CHILE
      ? Intl.NumberFormat("es", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: "decimal",
          useGrouping: true,
        }).format(amount)
      : Intl.NumberFormat("en", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(amount);

  return Number(formattedAmount) !== 0 ? formattedAmount : "0";
};
export const getFromLodash = (
  object: object,
  path: string,
  defaultValue: string | number | Array<never> | object
): string | number | Array<never> | object => {
  return defaultTo(get(object, path), defaultValue);
};

const setTagColor = (status?: string): ColorEnum => {
  return status === FilterEnum.APPROVAL ? ColorEnum.SUCCESS : ColorEnum.WARNING;
};

export const buildOneCell = (
  typeCell: TableBodyCellEnum | string,
  value1: string,
  value2?: string,
  style: ICellRow = { align: "left", spacing: 0 },
  status?: string
): ITableCellProps => {
  switch (typeCell) {
    case TableBodyCellEnum.TAG:
      return {
        props: {
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
          color: setTagColor(status),
          text: value1,
        },
        type: TableBodyCellEnum.TAG,
      };

    case TableBodyCellEnum.TITLE:
      return {
        props: {
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
          line1: value1,
          line2: value2,
          type: "twoLines",
        },
        type: TableBodyCellEnum.TITLE,
      };
    case TEXT_TWO_LINES:
      return {
        props: {
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
          line1: value1,
          line2: value2,
          type: "twoLines",
        },
        type: TableBodyCellEnum.TEXT,
      };

    default:
      return {
        props: {
          cellProps: {
            align: style.align,
            spacing: style.spacing,
          },
          line1: value1,
          type: "oneLine",
        },
        type: TableBodyCellEnum.TEXT,
      };
  }
};

export const setTransactionTypeText = (transactionType: string): string => {
  switch (transactionType) {
    case FilterEnum.CREDIT:
      return TABLE_LABEL.credit;
    default:
      return TABLE_LABEL.debit;
  }
};

export const setOriginText = (item: WalletTransaction): string => {
  if (get(item, "origin")) {
    switch (item.origin) {
      case FilterEnum.CARD_OUT:
        return TABLE_LABEL.card_out;
      case FilterEnum.CASH_OUT:
        return TABLE_LABEL.cash_out;
      case FilterEnum.LIQUIDACION:
        return TABLE_LABEL.liquidacion;
      default:
        return TABLE_LABEL.transfer_out;
    }
  } else {
    switch (item.payment_method) {
      case FilterEnum.AUTOMATIC:
        return TABLE_LABEL.commerce;
      default:
        return TABLE_LABEL.kushki;
    }
  }
};

export const buildDate = (
  created: string | number,
  renderDate: boolean
): string => {
  return format(
    new Date(created),
    renderDate
      ? DateFormatStringEnum.PATTERN_yyyyMMdd
      : DateFormatStringEnum.PATTERN_HHmmss
  );
};

export const buildDateDetailModal = (
  created: string | number,
  renderDate: boolean
): string => {
  return format(
    new Date(created),
    renderDate
      ? DateFormatStringEnum.PATTERN_ddMMyyyy
      : DateFormatStringEnum.PATTERN_HHmm
  );
};

export const renderSymbol = (transactionType: string): string => {
  switch (transactionType) {
    case FilterEnum.CREDIT:
      return TABLE_LABEL.credit_symbol;
    default:
      return TABLE_LABEL.debit_symbol;
  }
};

export const convertTableValues = (
  item: WalletTransaction,
  country?: string
): Record<string, string> => {
  return {
    [WalletTransactionPath.BALANCE]:
      formatCurrencyWallet(
        Number(get(item, WalletTransactionPath.BALANCE, 0)),
        country!
      ) +
      " " +
      get(item, WalletTransactionPath.CURRENCY),
    [WalletTransactionPath.TRX_ID]: defaultTo(
      get(item, WalletTransactionPath.TRX_ID),
      ""
    ),
    [WalletTransactionPath.TRX_STATUS]: defaultTo(
      TEXT_TRANSFORM_COLUMNS[item.transaction_status],
      ""
    ),
    [WalletTransactionPath.TOTAL_AMOUNT]:
      renderSymbol(get(item, WalletTransactionPath.TRX_TYPE, "")) +
      formatCurrencyWallet(
        Number(get(item, WalletTransactionPath.TOTAL_AMOUNT)),
        country!
      ) +
      " " +
      get(item, WalletTransactionPath.CURRENCY),
  };
};
